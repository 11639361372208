import {get, postJ} from "@/request";
import {ICourse,ICourseQuery, ICourseStatus} from "@/apis/coursesManage/courses/types";
import {IPageRes} from "@/apis/page";



/**
 * 列表 -分页
 * @param params 查询参数
 */
export const queryCourseListApi = (params: ICourseQuery) => get<IPageRes<ICourse[]>>("/golf/course/query", params)



/**
 * 添加
 */
export const createCourseApi = (data: ICourse) => postJ("/golf/course/create", data)

/**
 * 修改
 */
export const modifyCourseApi = (data: ICourse) => postJ("/golf/course/modify", data)

/**
 * 查询详情
 * @param id
 */
export const getCourseDetailApi = (id: string) => get<ICourse>("/golf/course/detail/" + id)

/**
 * 切换状态
 * @param id,status
 */
export const modifyCourseStatusApi = (data: ICourseStatus) => postJ("/golf/course/upAndDownShelf",data)

/**
 * 已有教练
 * @param id
 */
export const existingCoachApi = (params: any) => get("/golf/coachManage/existingCoach" ,params)


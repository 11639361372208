import {IPage} from "@/apis/page";

/**
 * @name: 视频教程
 * @author: lili
 * @date: 2023-05-31 10:38
 * @description：types
 * @update: 2023-05-31 10:38
 */

//商品查询
export interface ICourseQuery extends IPage{
  id?: string;//	id字符串(查询)		false
  courseTypes:CourseTypes;
  name?: string;//	名称		false
  status?: string;//	状态: 1=上架 2=下架
}
//状态
export interface ICourseStatus {
  id: string;
  status: number;
  courseTypes?:number;
}

export interface ICourse {
	id?: string;
	courseTypes?: CourseTypes;//课程类型: 1=视频课程 2=网红陪玩 3=1v1课程
	name?: string;
	price?: number;
	enrollQty?: number;
	enrollNumber?: number;
	courseStock?: number;
	courseBannerPic?: string;
	courseVideo?: string;
	status?: number;
	sort?: number;
	internetCelebrityCommission?: string;
	channelCommission?: string;
	courseDetails?: string;
	courseLocation?: string;
	coachUserId?: string;
	courseCardCount?: number;
	coachCommission?: string;
	addBy?: number;
	addTime?: string;
	editBy?: number;
	editTime?: string;
	courseVideoSkuVoList?: courseVideoSkuVoList[];
  courseVideoSkuDtoList?: courseVideoSkuVoList[];
	coachUserName?: string;
	ticketCodeNumber?: number;
}
export interface courseVideoSkuVoList {
	id?: string;
	courseId?: string;
	skuName?: string;
	video?: string;
  isUploadLoading?:boolean;
	sort: string;
  index?: number;
	addBy?: number;
	addTime?: string;
	editBy?: number;
	editTime?: string;
}
//	课程类型: 1=视频课程 2=网红陪玩 3=1v1课程
export enum CourseTypes {
  internet = 2,
  video = 1,
  course1v1 = 3,

}
